.product {
    height: 100%;
    &__head {
        margin-bottom: 8px;
        border: 1px solid rgb(var(--fireEngineRed));
    }
    &__body {
        height: 100%;
    }
    &__link {
        height: 100%;
        font-size: 12px;
    }
}