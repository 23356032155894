.catalog {
    padding-top: 36px;
    margin-bottom: 108px;
    &__accordion {
        &-head {
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 32px;
            border-bottom: 1px solid rgb(var(--fireEngineRed));
        }
        &-title {
            padding-bottom: 6px;
            margin-right: 15px;
            font-size: 20px;
            text-transform: uppercase;
            color: rgb(var(--charCoal));
        }
        &-control {
            flex-shrink: 0;
        }
        &-btn {
            position: relative;
            display: block;
            width: 32px;
            height: 32px;
            border: 1px solid rgb(var(--fireEngineRed));
            border-bottom: none;
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: calc(50% - .5px);
                left: calc(50% - 10px);
                width: 20px;
                height: 1px;
                background-color: rgb(var(--fireEngineRed));
            }
            &::after {
                transform: rotate(90deg);
                transition: transform var(--transitionDefault);
            }
            &.active {
                &::after {
                    transform: rotate(0);
                }
            }
        }
        &-body {
            display: none;
        }
    }
    &__row {
        &-title {
            margin-bottom: 32px;
            font-size: 20px;
            text-transform: uppercase;
            color: rgb(var(--charCoal));
        }
    }
    &__list {
        margin-right: -20px;
        margin-left: -20px;
        &-item {
            width: 20%;
            padding-right: 20px;
            padding-left: 20px;
            margin-bottom: 52px;
        }
    }
}

@media (max-width: 1200px) {
    .catalog {
        &__list {
            margin-right: -10px;
            margin-left: -10px;
            &-item {
                padding-right: 10px;
                padding-left: 10px;
            }
        }
    }
}

@media (max-width: 992px) {
    .catalog {
        margin-bottom: 60px;
        &__list {
            &-item {
                width: 25%;
            }
        }
    }
}

@media (max-width: 767px) {
    .catalog {
        &__list {
            &-item {
                width: 33.33%;
            }
        }
    }
}

@media (max-width: 480px) {
    .catalog {
        margin-bottom: 30px;
        &__accordion {
            &-title {
                font-size: 16px;
            }
        }
        &__list {
            &-item {
                width: 50%;
                margin-bottom: 30px;
            }
        }
    }
}